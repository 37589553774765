import image from '../../images/blog/demographic-perspective.png'

export default {
  url: '/blog/demographic-perspective',
  title: `A Demographic Perspective of the Disparities in Medication Nonadherence`,
  author: 'AdhereTech',
  publishDate: `2024-05-01`,
  image,
  body: `
    <p>
      The landscape of healthcare is continually evolving, with advancements in medical science and technology reshaping the way we approach patient care. One critical aspect that significantly influences healthcare outcomes is medication adherence, the extent to which individuals follow prescribed medication regimens (Osterberg & Blaschke, 2005). Amidst this, demographics emerge as a powerful determinant, exerting a profound impact on medication nonadherence (Whittle et al., 2016) and (Xie et al., 2020) and research has consistently shown that certain groups are more negatively impacted by medication nonadherence than others (Oates et al., 2020) and (McQuaid and Landier, 2018). Demographic factors such as age, gender, socioeconomic status, education, and cultural background play pivotal roles in shaping individuals' attitudes, beliefs, and behaviors toward medication adherence; understanding the intricate interaction between demographics and medication adherence is crucial for tailoring strategies that address the needs of diverse patient populations.
    </p>
    <p>
      The impact of socioeconomic status, a construct that represents an individual’s position relative to others in the community (Alsabbagh et al.), on medication adherence is undeniable; individuals with lower incomes may face barriers such as limited access to healthcare, lack of insurance coverage, and difficulties affording medications (Meichenbaum & Turk, 1987). Moreover, there is an underlying lack of stability experienced by those in a lower socioeconomic class (Sheehy-Skeffington and Rea, 2020), which has been shown to increase stress and anxiety – a factor that we know has a negative impact on medication adherence (Roohafza et al., 2016). The cost of prescriptions can be a significant deterrent for those hailing from a lower socioeconomic background, resulting in skipped doses or patients avoiding filling prescriptions altogether (Nekui et al., 2020), which creates a knock-on effect as they may be paying for additional auto renewal prescriptions when patients haven’t taken all of their existing meds. Addressing socioeconomic disparities requires an approach that encompasses both healthcare policy and community-level interventions. These may include advocating for policies that improve healthcare affordability, reducing the financial burdens on vulnerable populations, and community-based initiatives involving local organizations and support networks who can play a crucial role in providing assistance with transportation, childcare, or other logistical challenges that hinder medication adherence.
    </p>
    <p>
      Age plays a crucial role in medication adherence, with distinct patterns emerging across different life stages. Research has shown that older adults may face challenges due to complex medication regimens, cognitive decline, and physical limitations (Kim et al., 2019). On the other hand, younger individuals might struggle with adherence due to lifestyle factors, such as forgetfulness or a perception of invincibility (Chawa et al., 2020); although there is debate in literature regarding which group is more susceptible to medication nonadherence, it is widely accepted that age is an important factor (Weingarten and Cannon, 2020) tailoring interventions to address the unique needs of each age group is essential for improving adherence rates.
    </p>
    <p>
      A patient’s cultural beliefs and practices significantly influence health behaviors, including medication adherence (Shahin et al., 2019), spirituality and religion have also been increasingly identified as negatively impacting medication adherence as patients instead anticipate divine outcomes (Kasahun et al., 2022) and some cultures may prioritize alternative therapies or have distrust in conventional medicine leading to nonadherence. Participants in a previous study who had strong beliefs in the effectiveness of Traditional Chinese Medication were found to be less adherent to western medication (Wang et al., 2011). Moreover, language barriers and cultural differences can hinder effective communication between healthcare providers and patients – communication between patient and healthcare professionals has been shown to be a prime factor in adherence (Frankel, 1995). Culturally sensitive healthcare approaches, including patient education and outreach initiatives are crucial to overcoming these challenges.
    </p>
    <p>
      Health literacy, or the ability to understand and navigate health information, is a key determinant of medication adherence (Miller, 2016). Individuals with low health literacy may struggle to comprehend prescription instructions, potential side effects and the importance of consistent medication use. Better adherence is shown to be closely associated with a better understanding of the patient’s illness (Lee et al., 2017) and results from a rheumatoid arthritis study showed that a majority of participants demonstrated a poor understanding of the disease or their medication (Kobue et al., 2017).
    </p>
    <p>
      In literature, the impact of ethnicity on compliance to treatment is shown to be one of the strongest predictors of medication adherence (Shahin, et al., n.d.). Genetic variations affect how the body metabolizes drugs, creating differences in the rate at which drugs are broken down and eliminated from the body (Wang et al., 2011), this can influence a drug's effectiveness and potential side effects, meaning some may experience a stronger or weaker response to a medication based on their genetic profile (Roden et al.). A total of 113 drug labels approved by the US Food and Drug Administration (FDA) include information about variability in patient response secondary to genetic variability (Center for Drug Evaluation and Research, 2019); this highlights the need for interventions such as predictive testing, which would help guide healthcare professionals in selecting the most appropriate medications from the start, reducing the trial-and-error process and improving adherence by avoiding ineffective or poorly tolerated treatments.
    </p>
    <p>
      Location has been shown to directly influence a patient’s likelihood of adhering to medication (Erickson and Lin, 2014) and (Learnihan et al., 2021), as rural or remote areas may face challenges related to limited access to healthcare facilities, pharmacies, and healthcare information impacting medication adherence (Couto et al., 2015). Mitigating medication nonadherence influenced by geographic factors necessitates interventions that account for the challenges faced by individuals in different locations. In remote or rural areas with limited access to healthcare facilities, the implementation of telehealth services can bridge the gap, allowing patients to consult with healthcare providers virtually and receive necessary prescriptions. Mobile clinics or outreach programs can be organized to bring healthcare services directly to underserved areas, facilitating easier access to medications. 
    </p>
    <p>
      Medication nonadherence is a complex issue with a myriad of  determinants and its impact varies significantly among different demographics; recognizing and addressing these disparities is essential for developing effective interventions that promote better health outcomes for all. By understanding the unique challenges faced by specific groups; healthcare providers, policymakers, and communities, they can work collaboratively to implement targeted solutions, fostering a more inclusive and equitable healthcare system.
    </p>
  `,
  references: [
    `Osterberg, L., & Blaschke, T. (2005). Adherence to medication. <em>New England Journal of Medicine, 353</em>(5), 487–497. https://doi.org/10.1056/nejmra050100`,
    `Oates, Gabriela R., et al. “Social Risk Factors for Medication Nonadherence: Findings from the CARDIA Study.” <em>American Journal of Health Behavior</em>, vol. 44, no. 2, 1 Mar. 2020, pp. 232–243, https://doi.org/10.5993/ajhb.44.2.10.`,
    `McQuaid, Elizabeth L., and Wendy Landier. “Cultural Issues in Medication Adherence: Disparities and Directions.” <em>Journal of General Internal Medicine</em>, vol. 33, no. 2, 4 Dec. 2018, pp. 200–206, www.ncbi.nlm.nih.gov/pmc/articles/PMC5789102/, https://doi.org/10.1007/s11606-017-4199-3.`,
    `Kim, Seung Jae, et al. “Impact of Number of Medications and Age on Adherence to Antihypertensive Medications.” <em>Medicine</em>, vol. 98, no. 49, Dec. 2019, p. e17825, https://doi.org/10.1097/md.0000000000017825.`,
    `Chawa, Mansi Sethi, et al. “The Impact of Socioeconomic Status, Race/Ethnicity, and Patient Perceptions on Medication Adherence in Depression Treatment.” <em>The Primary Care Companion for CNS Disorders</em>, vol. 22, no. 6, 10 Dec. 2020, https://doi.org/10.4088/pcc.20m02625.`,
    `Alsabbagh, M.H.D. Wasem, et al. “Socioeconomic Status and Nonadherence to Antihypertensive Drugs: A Systematic Review and Meta-Analysis.” <em>Value in Health</em>, vol. 17, no. 2, Mar. 2014, pp. 288–296, https://doi.org/10.1016/j.jval.2013.11.011.`,
    `Meichenbaum, D., & Turk, D. C. (1987). <em>Facilitating treatment adherence: A practitioner's guidebook</em>. Plenum Press.`,
    `Shahin, Wejdan, et al. “The Impact of Personal and Cultural Beliefs on Medication Adherence of Patients with Chronic Illnesses: A Systematic Review.” <em>Patient Preference and Adherence</em>, vol. Volume 13, no. 1, July 2019, pp. 1019–1035, https://doi.org/10.2147/ppa.s212046.`,
    `Kasahun, Asmamaw Emagn, et al. “Impact of Personal Cultural and Religious Beliefs on Medication Adherence among Patients with Chronic Diseases at University Hospital in Northwest Ethiopia .” <em>Patient Preference and Adherence</em>, vol. Volume 16, July 2022, pp. 1787–1803, https://doi.org/10.2147/ppa.s370178.`,
    `Wang, L., McLeod, H.L. and Weinshilboum, R.M., 2011. Genomics and drug response. <em>New England Journal of Medicine</em>, 364(12)`,
    `Center for Drug Evaluation and Research. “Table of Pharmacogenomic Biomarkers.” <em>U.S. Food and Drug Administration</em>, 2019, www.fda.gov/drugs/science-and-research-drugs/table-pharmacogenomic-biomarkers-drug-labeling.`,
    `Couto, Joseph R, et al. “Geographic Variation in Medication Adherence in Commercial and Medicare Part D Populations.” <em>Journal of Managed Care Pharmacy</em>, vol. 20, no. 8, 14 Sept. 2015, pp. 834–842, https://doi.org/10.18553/jmcp.2014.20.8.834. Accessed 2 May 2023.`,
    `Erickson, Steven R., and Yuan-Nung Tony Lin. “Geospatial Analysis of Statin Adherence Using Pharmacy Claims Data in the State of Michigan.” <em>Journal of Managed Care Pharmacy</em>, vol. 20, no. 12, Dec. 2014, pp. 1208–1215, https://doi.org/10.18553/jmcp.2014.20.12.1208. Accessed 24 Oct. 2020.`,
    `Learnihan, Vincent, et al. “Geographic Variation in and Contextual Factors Related to Biguanide Adherence amongst Medicaid Enrolees with Type 2 Diabetes Mellitus.” <em>SSM - Population Health</em>, vol. 17, 24 Dec. 2021, p. 101013, www.ncbi.nlm.nih.gov/pmc/articles/PMC8784336/, https://doi.org/10.1016/j.ssmph.2021.101013. Accessed 23 Feb. 2024.`,
    `Erickson, S.R., Bravo, M. and Tootoo, J., 2020. Geosocial factors associated with adherence to statin medications. <em>Annals of Pharmacotherapy</em>, 54(12), pp.1194-1202.`,
    `Whittle, Jeff, et al. “Clinical and Demographic Correlates of Medication and Visit Adherence in a Large Randomized Controlled Trial.” <em>BMC Health Services Research</em>, vol. 16, no. 1, 8 July 2016, https://doi.org/10.1186/s12913-016-1471-x.`,
    `Xie, Zhenzhen, et al. “An Examination of the Socio-Demographic Correlates of Patient Adherence to Self-Management Behaviors and the Mediating Roles of Health Attitudes and Self-Efficacy among Patients with Coexisting Type 2 Diabetes and Hypertension.” <em>BMC Public Health</em>, vol. 20, no. 1, 12 Aug. 2020, https://doi.org/10.1186/s12889-020-09274-4.`,
    `WEINGARTEN, M A, and B S CANNON. “Age as a Major Factor Affecting Adherence to Medication for Hypertension in a General Practice Population.” <em>Family Practice</em>, vol. 5, no. 4, 1988, pp. 294–296, https://doi.org/10.1093/fampra/5.4.294. Accessed 29 Sept. 2020.`,
    `Roden, Dan M., et al. “Pharmacogenomics.” <em>Circulation</em>, vol. 123, no. 15, 19 Apr. 2011, pp. 1661–1670, www.ncbi.nlm.nih.gov/pmc/articles/PMC3093198/, https://doi.org/10.1161/circulationaha.109.914820.`,
  ],
}
