import image from '../../images/blog/impact-cognitive-processes.png'

export default {
  url: '/blog/impact-cognitive-processes',
  title: `The Impact of Cognitive Processes on Medication Adherence`,
  author: `AdhereTech`,
  publishDate: `2024-06-27`,
  image,
  body: `
    <h3>Overview:</h3>
    <ul>
      <li>
        Bandura’s <em>Social Cognitive Theory (SCT)</em> proposes that cognitive processes such as self-efficacy, perceived barriers, and outcome expectations significantly influence a patient’s medication adherence, shaping their beliefs and decision-making processes regarding their medication regimens.
      </li>
      <li>
        Self-efficacy enhances adherence by boosting patient confidence in overcoming obstacles, while perceived barriers and negative outcome expectations can hinder adherence if not addressed.
      </li>
      <li>
        Understanding these cognitive factors within the SCT framework allows healthcare providers to create interventions that are targeted to improve medication adherence.
      </li>
    </ul>
    <hr/>
    <h3>The Impact of Cognitive Processes on Medication Adherence</h3>
    <p>
      Medication adherence, the degree to which patients follow their prescribed medication regimens, is a complex behavior influenced by a series of factors. Albert Bandura’s Social Cognitive Theory (SCT) proposes a triadic reciprocal causation model, where specific aspects of cognitive processes, behaviors, and environments continuously and mutually influence each other bidirectionally (Bandura, 2013). Among these, cognitive processes, such as self-efficacy, outcome expectancies, and perceived barriers, play a significant role (Bandura & National Institute of Mental Health, 1986). Regarding medication adherence, these cognitive factors shape a patient's beliefs and decision-making regarding their medication regimens. 
    </p>
    <h4><em>Self-Efficacy and Medication Adherence</em></h4>
    <p>
      Self-efficacy refers to a patient’s belief in their ability to adhere to their medication despite adversity (Bandura, 1999; Náfrádi et al., 2017). In the context of medication adherence, patients with high self-efficacy are confident in their ability to adhere to their medication regimen even when faced with obstacles. This confidence is crucial as it motivates patients to continue taking their medication correctly and consistently. For instance, a patient with high self-efficacy will be more likely to create strategies on how to remember their medication effectively.
    </p>
    <h4><em>Perceived Barriers</em></h4>
    <p>
      Perceived barriers represent obstacles that may hinder a patient's ability to adhere to their medication schedules. These can include practical issues such as forgetfulness, complex medication regimens, and experiencing adverse side effects from medication (Bandura, 1999; Mailey et al., 2016). When patients perceive these barriers as insuperable, their adherence decreases. Addressing these barriers by providing solutions, such as reminder systems or side effect management strategies, can improve adherence rates.
    </p>
    <h4><em>Outcome Expectations</em></h4>
    <p>
      Outcome expectations involve a patient's beliefs about the consequences of adhering to their prescribed regimen (Bandura, 1999; Mailey et al., 2016). These expectations can be both positive and negative. Patients who believe adhering to their medication will lead to significant health benefits, such as symptom relief and improved quality of life, are more likely to adhere consistently (Bandura, 1999). Conversely, if a patient focuses excessively on the drawbacks of medication adherence, such as potential side effects or the inconvenience of taking medication, their adherence is likely to suffer (Mailey et al., 2016).
    </p>
    <h4><em>Cognitive Process within SCT Framework</em></h4>
    <p>
      These cognitive processes - self-efficacy, perceived barriers, and outcomes expectations -illustrate the intricate interplay between personal beliefs and behavior, specifically medication adherence. Bandura's theory emphasizes that these processes interact dynamically with behavior (taking medication) and environmental influences (perceived barriers) to influence adherence.
    </p>
    <p>
      This triadic reciprocal causation model posits that each of these components continuously influences the others, creating a complex array of factors that affect medication adherence (Bandura & National Institute of Mental Health, 1986). Understanding how cognitive processes impact medication adherence through the lens of SCT provides valuable insights for healthcare professionals. By boosting patient’s self-efficacy, addressing perceived barriers, and enhancing positive outcome expectations, healthcare providers can develop more effective interventions to improve adherence.
    </p>
  `,
  references: [
    `Bandura, A., & National Institute of Mental Health. (1986). Social foundations of thought and action: A social cognitive theory. Prentice-Hall, Inc.`,
    `Bandura, A. (2013). Health promotion from the perspective of social cognitive theory. In Understanding and Changing Health Behaviour (1st. ed). Psychology Press.`,
    `Bandura, A. (2013). Health promotion from the perspective of social cognitive theory. In Understanding and Changing Health Behaviour (1st. ed). Psychology Press.`,
    `Náfrádi, L., Nakamoto, K., & Schulz, P. J. (2017). Is patient empowerment the key to promote adherence? A systematic review of the relationship between self-efficacy, health locus of control and medication adherence. PloS One, 12(10). https://doi.org/10.1371/journal.pone.0186458.`,
    `Mailey, E. L., Phillips, S. M., Dlugonski, D., & Conroy, D. E. (2016). Overcoming barriers to exercise among parents: a social cognitive theory perspective. Journal of Behavioral Medicine, 39(4), 599–609. https://doi.org/10.1007/s10865-016-9744-8.`,
  ],
}
