// TODO: look into using a GraphQL query to dynamically retrieve the list of blog article files

import moreThanMedicine from './more-than-medicine'
import demographicPerspective from './demographic-perspective'
import silentEpidemic from './silent-epidemic'
import adherenceCrisis from './adherence-crisis'
import healthierPlanet from './healthier-planet'
import understandingMedicationManagement from './understanding-medication-management'
import impactCognitiveProcesses from './impact-cognitive-processes'

export const featuredArticle = understandingMedicationManagement

export const articles = [
  impactCognitiveProcesses,
  demographicPerspective,
  silentEpidemic,
  adherenceCrisis,
  healthierPlanet,
  moreThanMedicine,
]
