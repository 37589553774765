import React, { useState } from 'react'
import { Link } from 'gatsby'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'

import { articles, featuredArticle } from '../content/blogArticles'
import CallToActionLink from '../components/CallToActionLink'

const BlogPage = () => {
  const [articleTilePage, setArticleTilePage] = useState(0)

  const renderFeaturedIntro = () => {
    const body = featuredArticle.body.trim()

    if (body.startsWith(`<h3>Overview:</h3>`)) {
      let overview = body.split('\n').map((a) => a.trim())
      overview = overview
        .slice(1, overview.indexOf('</ul>') + 1)
        .filter((a) => !a.includes('ul>') && !a.includes('li>'))
        .join(' ')
      if (overview.length > 300) {
        overview = overview.slice(0, 300) + '...'
      }
      return overview
    }

    const paragraphs = body
      .split('\n')
      .map((a) => a.trim())
      .filter((a) => a && a !== '<p>' && a !== '</p>')

    let firstParagraph = paragraphs[0]
    if (firstParagraph.length > 300) {
      firstParagraph = firstParagraph.slice(0, 300) + '...'
    }

    return firstParagraph
  }

  const renderArticleTiles = () => {
    return articles.slice(articleTilePage * 4, articleTilePage * 4 + 4).map((article, i) => {
      return (
        <Link to={article.url} className="article-tile" key={article.title}>
          <h3 className="article-title">{article.title}</h3>
          <div className="article-author">By {article.author}</div>
        </Link>
      )
    })
  }

  const navigateArticleTiles = (num) => {
    if (articleTilePage + num < 0 || (articleTilePage + num) * 4 >= articles.length) return
    setArticleTilePage(articleTilePage + num)
  }

  return (
    <main className="blogPage">
      <div className="page-section">
        <div>
          <h2 className="page-heading">Latest from AdhereTech</h2>
          <div className="featured-article">
            <div className="featured-content">
              <h3 className="featured-title">{featuredArticle.title}</h3>
              <p className="featured-intro">{renderFeaturedIntro()}</p>
              <CallToActionLink color="blue" linkText="Read more" linkUrl={featuredArticle.url} />
            </div>
            <img className="featured-image" src={featuredArticle.image} alt="Featured image" />
          </div>
        </div>
      </div>
      <div className="page-section--blue">
        <div className="page-section-content">
          <div className="article-tiles">{renderArticleTiles()}</div>
          <div className="article-tile-nav">
            <div
              className={`nav-left${articleTilePage - 1 < 0 ? ' disabled' : ''}`}
              onClick={() => navigateArticleTiles(-1)}
            >
              <FaArrowLeft />
            </div>
            <div
              className={`nav-right${(articleTilePage + 1) * 4 >= articles.length ? ' disabled' : ''}`}
              onClick={() => navigateArticleTiles(1)}
            >
              <FaArrowRight />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default BlogPage
