import image from '../../images/blog/more-than-medicine.png'

export default {
  url: '/blog/more-than-medicine',
  title: `More Than Medicine: Understanding the Impact of Nonadherence on Quality of Life`,
  author: `AdhereTech`,
  publishDate: `2024-03-21`,
  image,
  body: `
    <p>
      In chronic disease management, adherence to prescribed medications plays a pivotal role in managing and mitigating various health conditions, and nonadherence can lead to inadequate disease control, exacerbation of symptoms and an increased risk of complications, ultimately diminishing the patient’s quality of life (Jimmy and Jose, 2011); however, 50-60% of patients with chronic illnesses miss doses, take the wrong doses, or drop off treatment in the first year (Bosworth et al., 2011).
    </p>
    <p>
      Certain medications play a crucial role in preserving cognitive function, especially in conditions like Alzheimer's disease; medication nonadherence can accelerate cognitive decline, negatively impacting an individual's ability to perform daily tasks and maintain independence (Smith et al., n.d.). The uncertainty of living with poorly managed health has been shown to contribute to heightened stress and anxiety levels (Nasif, n.d.); it can also create a financial strain, due to an increase in avoidable hospitalizations (an estimated 70% of medication-related hospital admissions are linked to medication nonadherence (Bosworth et al., n.d.).
    </p>
    <p>
      Chronic conditions aggravated by medication nonadherence can impede individuals from fully participating in work and social activities (Lu et al., 2021) and the reduced ability to engage in daily tasks and contribute to society can have profound effects on self-esteem and overall life satisfaction (Oluwole et al., n.d.). A previous study has shown that patients with asthma resulted in productivity losses of up to ± $1,024 per patient per year (Joshi et al., n.d.); the financial burden of medical expenses, coupled with potential loss of income due to missed work can create a significant strain on individuals and families (McGuire and Luga, 2014).
    </p>
    <p>
      Medication nonadherence doesn't just affect the individual; it strains relationships with family, friends, and caregivers; the stress of caring for someone dealing with the repercussions of nonadherence can lead to uneasy connections and emotional exhaustion (Kretchy et al., 2014). Studies have shown that family caregiver burden has a direct negative effect on medication adherence (Liu et al., 2022); however, it has also been shown that support from family and friends has a positive impact on medication adherence (Guo et al.); it is therefore important to develop strategies that help caregivers, family and friends to cope with illness.
    </p>
    <p>
      Consistently following prescribed medication regimens empowers individuals to better manage their health conditions, preventing the exacerbation of symptoms and reducing the risk of complications by gaining the full efficacy of their medication (Jimmy and Jose, n.d.). When patients are unintentionally missing doses, there is a simple and effective solution to help increase compliance - the successful formation of new habits. Research shows that the first 66 days are crucial in habit formation (Lally et al., 2010) and there should be an increased focus during this period; this has been shown to have a strong positive correlation with medication adherence (Badawy et al., 2020), possibly because taking medication is a daily repetitive behaviour that could be associated with contextual cues (Fontanet et al., 2021). By promoting this practice, health care professionals can provide patients with a structured framework that not only promotes adherence to treatment regimens but also contributes to overall well-being, empowerment, and a sense of control over their health.
    </p>
  `,
  references: [
    `Jimmy, Benna, & Jose, J. (2011a). Patient medication adherence: Measures in daily practice. <em>Oman Medical Journal, 26</em>(3), 155–159. https://doi.org/10.5001/omj.2011.38`,
    `“Medication Adherence: A Call for Action.” <em>American Heart Journal</em>, vol. 162, no. 3, Sept. 2011, pp. 412–424, www.ncbi.nlm.nih.gov/pmc/articles/PMC3947508/, https://doi.org/10.1016/j.ahj.2011.06.007.`,
    `Feng et al. QJM. 2021, 115(5)279-286`,
    `Smith, D., Lovell, J., Weller, C., Kennedy, B., Winbolt, M., Young, C., & Ibrahim, J. (2017). A systematic review of medication non-adherence in persons with dementia or cognitive impairment. <em>PLOS ONE, 12</em>(2). https://doi.org/10.1371/journal.pone.0170651`,
    `Nasif, J. (2015). The emotional impact of chronic illness. <em>Journal of Psychology & Clinical Psychiatry, 3</em>(6). https://doi.org/10.15406/jpcpy.2015.03.00177`,
    `American Heart J. Medication Adherence: A call for Action. 2011 Sep; 162(3) https://pubmed.ncbi.nlm.nih.gov/21884856/`,
    `Lu, Z. Kevin, et al. “Impact of Cost-Related Medication Nonadherence on Economic Burdens, Productivity Loss, and Functional Abilities: Management of Cancer Survivors in Medicare.” <em>Frontiers in Pharmacology</em>, vol. 12, 29 June 2021, https://doi.org/10.3389/fphar.2021.706289.`,
    `Oluwole, E., Osibogun, O., Adegoke, O., Adejimi, A., Adewole, A., & Osibogun, A. (2019). Medication adherence and patient satisfaction among hypertensive patients attending outpatient clinic in Lagos University Teaching Hospital, Nigeria. <em>Nigerian Postgraduate Medical Journal, 26</em>(2), 129. https://doi.org/10.4103/npmj.npmj_48_19`,
    `Joshi, A. V., Madhavan, S. S., Ambegaonkar, A., Smith, M., Scott, V. (Ginger), & Dedhia, H. (2006). Association of medication adherence with workplace productivity and health-related quality of life in patients with asthma. <em>Journal of Asthma, 43</em>(7), 521–526. https://doi.org/10.1080/02770900600857010`,
    `McGuire, M., & Iuga. (2014). Adherence and Health Care Costs. <em>Risk Management and Healthcare Policy</em>, 35. https://doi.org/10.2147/rmhp.s19801`,
    `Kretchy, I. A., Osafo, J., Agyemang, S. A., Appiah, B., & Nonvignon, J. (2018). Psychological burden and caregiver-reported non-adherence to psychotropic medications among patients with schizophrenia. <em>Psychiatry Research, 259</em>, 289–294. https://doi.org/10.1016/j.psychres.2017.10.034`,
    `Liu, L., Huang, W., Huang, Z., Liu, J., Zheng, X., Tang, J., Wu, Y., Wang, X., Liao, Y., & Cong, L. (2022). Relationship between family caregiver burden and medication adherence in patients with mechanical valve replacement: A structural equation model. <em>Patient Preference and Adherence, Volume 16</em>, 3371–3382. https://doi.org/10.2147/ppa.s383269`,
    `Aremu, Taiwo Opeyemi, et al. “Medication Adherence and Compliance: Recipe for Improving Patient Outcomes.” <em>Pharmacy</em>, vol. 10, no. 5, 1 Oct. 2022, p. 106, www.mdpi.com/2226-4787/10/5/106, https://doi.org/10.3390/pharmacy10050106.`,
    `Guo, Aizhen, et al. “Impact of Health Literacy and Social Support on Medication Adherence in Patients with Hypertension: A Cross-Sectional Community-Based Study.” <em>BMC Cardiovascular Disorders</em>, vol. 23, no. 1, 19 Feb. 2023, https://doi.org/10.1186/s12872-023-03117-x. Accessed 3 Mar. 2023.`,
    `Badawy, Sherif M, et al. “Habit Strength, Medication Adherence, and Habit-Based Mobile Health Interventions across Chronic Medical Conditions: Systematic Review.” <em>Journal of Medical Internet Research</em>, vol. 22, no. 4, 28 Apr. 2020, p. e17883, https://doi.org/10.2196/17883.`,
    `Gadkari, Abhijit S, and Colleen A McHorney. “Unintentional Non-Adherence to Chronic Prescription Medications: How Unintentional Is It Really?” <em>BMC Health Services Research</em>, vol. 12, no. 1, 14 June 2012, https://doi.org/10.1186/1472-6963-12-98.`,
    `Fontanet, Constance P., et al. “Randomised Controlled Trial Targeting Habit Formation to Improve Medication Adherence to Daily Oral Medications in Patients with Gout.” <em>BMJ Open</em>, vol. 11, no. 11, 1 Nov. 2021, p. e055930, bmjopen.bmj.com/content/11/11/e055930, https://doi.org/10.1136/bmjopen-2021-055930. Accessed 11 Mar. 2024.`,
    `Lally, Phillippa, et al. “How Are Habits Formed: Modelling Habit Formation in the Real World.” <em>European Journal of Social Psychology</em>, vol. 40, no. 6, 2010, pp. 998–1009, citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.695.830&rep=rep1&type=pdf, https://doi.org/10.1002/ejsp.674.`,
  ],
}
